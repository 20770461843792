import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

// Components
import LoginForm from '../../organisms/LoginForm'
import RegisterForm from '../../organisms/RegisterForm'
import ForgotPassForm from '../../organisms/ForgotPassForm'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Constants
import { auth } from '../../../config/localization'

// Styles
import {
  backArrow,
  loginLogo,
  crisolOverlay,
  loginContainer,
  gradientWrapper,
} from './styles.module.scss'

const Login: React.FC = () => {
  const { language } = useContext(LocalizationContext)

  const [form, setForm] = useState<'Login' | 'Register' | 'Forgot'>('Login')
  const [localization, setLocalization] = useState(auth.Default)

  const renderForm = () => {
    switch (form) {
      case 'Register':
        return (
          <RegisterForm
            localization={{
              ...localization.common,
              ...localization.register,
            }}
            setForm={setForm}
          />
        )
      case 'Forgot':
        return (
          <ForgotPassForm
            localization={{
              ...localization.common,
              ...localization.forgot,
            }}
          />
        )
      default:
        return (
          <LoginForm
            localization={{
              ...localization.common,
              ...localization.login,
            }}
            setForm={setForm}
          />
        )
    }
  }

  const arrowClick = () => {
    switch (form) {
      case 'Register':
        setForm('Login')
        break
      case 'Forgot':
        setForm('Login')
        break
      default:
        window.history.back()
    }
  }

  useEffect(() => {
    const newLocalization = auth[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  return (
    <>
      <Helmet>
        <title>
          Login - Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Crea una cuenta para contar con los beneficios de subscribirte a Dilan: las crónicas de Covak.'
              : 'Create an account and receive all the benefits that come with subscribing to Dilan: the Chronicles of Covak.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, homepage'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, homepage'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <div className={`block-wrapper ${gradientWrapper}`}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={backArrow}
          onClick={() => {
            arrowClick()
          }}
        />
        <div className={`container ${loginContainer}`}>
          <img
            className={loginLogo}
            src="https://dilancovak.com/.img/system/logo_covak.png"
          />
          {renderForm()}
        </div>
        <img
          className={crisolOverlay}
          src="https://dilancovak.com/.img/system/login_background.png"
        />
      </div>
    </>
  )
}

export default Login
